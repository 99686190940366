import * as React from 'react';
import { Embed } from 'semantic-ui-react';
import { withLayout } from '../../components/layout';
import { H1 } from '../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';

function BoreholeAcoustics() {
  return (
    <>
      <H1>Borehole Acoustics Modeling</H1>
      <p>
        Sonic-logging tools record acoustic signals in fluid filled boreholes.
        These waves, before being recorded, interact with the borehole casing,
        the surrounding rock formations, and other complications like fractures.
        Numerical simulations through digital recreations of these scenarios are
        mandatory to understand and act on these signals.
      </p>
      <p>
        Common difficulties that arise in such simulations range from the
        accurate handling of fluid-solid interface conditions, the resolution of
        sub-wavelength fracture networks, and ensuring the efficiency of
        simulations in the presence of rapidly varying model geometry, among
        others.
      </p>
      <p>
        Salvus simulates multiple wave propagation physics, including coupled
        acoustic / viscoelastic waves propagating through air or water-filled
        boreholes in two and three dimensions. These simulations provide a
        quantitative evaluation of subsurface materials and structures, and
        assist in characterizing the imprint of thin fractures.
      </p>
      <p>
        This wavefield snapshot from a Salvus simulation highlights a few
        complexities our code readily handles:
      </p>
      <StaticImage
        src="../../images/applications/borehole_acoustics/borehole_annotated.png"
        alt="Annotated picture of a borehole acoustics wavefield."
        width={1200}
        quality={100}
        placeholder="tracedSVG"
        css={{ marginBottom: '3ex', marginTop: '3ex' }}
      />
      <p>
        In addition, the following features relevant for borehole acoustic
        modeling, are also supported:
        <ul>
          <li>Arbitrary space-dependent anisotropic symmetry systems</li>
          <li>Attenuating media</li>
          <li>Spatially varying volumetric material properties</li>
          <li>Exact material discontinuities</li>
          <li>
            Simulates the full wavefield: including body, surface, and Stoneley
            waves.
          </li>
        </ul>
      </p>
      <p>
        Salvus can perform all necessary steps for borehole acoustic waveform
        simulations including model building, mesh generation, all the way to
        the actual wavefield simulations with a single cohesive and unified
        interface.
      </p>
      <p css={{ marginTop: '2ex' }}>
        The following movie shows a 2-D borehole acoustic simulation. Note in
        particular the thin fracture and the interacting elastic and acoustic
        wavefields.
      </p>
      <Embed
        id="465477878"
        source="vimeo"
        autoplay={false}
        placeholder="https://vumbnail.com/465477878.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />
    </>
  );
}

export default withLayout(BoreholeAcoustics);
